import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Link } from "gatsby";
import styled from "styled-components";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import CardContainer from "~/components/Containers/CardContainer";
import Flex from "~/components/Containers/Flex";
import LoadingError from "~/components/Loaders/LoadingError";
import PackCard from "~/components/Pack/PackCard";
import packService from "~/utils/api/v1/packService";
import Pack from "~/utils/interfaces/Pack";

const CountrySelect = styled.div`
  max-width: 50%;
`;

const PacksIndex = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>({});
  const [allPacks, setAllPacks] = useState<Record<string, Pack[]>>({});
  const [packs, setPacks] = useState<Pack[]>([]);
  const [selectedLocale, setSelectedLocale] = useState<string>("Chile");

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true);

      try {
        const req = await packService.fetchPacks({});
        setAllPacks({
          Chile: req.data.filter((pack: Pack) => pack.country === "Chile"),
          México: req.data.filter((pack: Pack) => pack.country === "Mexico"),
        });
      } catch (err) {
        setError(err);
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    setPacks(allPacks[selectedLocale]);
  }, [selectedLocale, allPacks]);

  return (
    <PrivateRoute>
      <LoadingError
        loading={loading}
        error={error}
      />
      {!loading && (
        <>
          <Flex
            align="center"
            justify="space-between"
            margin="0 0 2rem 0"
          >
            <h1>Packs</h1>
            <CountrySelect>
              <FormControl fullWidth>
                <InputLabel id="fonasa">País</InputLabel>
                <Select
                  disabled={loading}
                  value={selectedLocale}
                  onChange={(e) => {
                    setSelectedLocale(e.target.value as string);
                  }}
                  fullWidth
                >
                  <MenuItem
                    key="cl"
                    value="Chile"
                  >
                    Chile
                  </MenuItem>
                  <MenuItem
                    key="mx"
                    value="México"
                  >
                    México
                  </MenuItem>
                </Select>
              </FormControl>
            </CountrySelect>
            <Link
              to="/packs/new"
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="outlined"
                size="large"
                color="primary"
              >
                Crear nuevo
              </Button>
            </Link>
          </Flex>
          <CardContainer cols={2}>
            {packs?.map((pack: Pack) => (
              <PackCard
                key={pack.id}
                name={pack.name}
                id={pack.id}
              />
            ))}
          </CardContainer>
        </>
      )}
    </PrivateRoute>
  );
};

export default PacksIndex;
